import React from "react"
import Layout from "../components/layout"
import { container, subSection } from "../styles/playlist.module.css"

const PlaylistPage = () => {
  return (
    <Layout pageTitle={"Playlist | Eames Maloney"}>
      <container className={container}>
        <h1>Playlist</h1>

        <container className={subSection}>
          <p>
            Music played a huge part of our preparations for the wedding and we
            spent longer than we would like to admit curating the playlists you
            heard throughout the day.
          </p>
          <p>Because of that we want to share those playlists with you.</p>
        </container>

        <container className={subSection}>
          <p align="center">
            <i>Pre-ceremony</i>
          </p>
          <div
            style={{
              left: 0,
              width: "100%",
              height: "352px",
              position: "relative",
            }}
          >
            <iframe
              title="meames-pre-ceremony"
              src="https://open.spotify.com/embed/playlist/4QaYzFxAXiB0VfCX4qKoB8?utm_source=oembed&theme=0"
              style={{
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
                border: 0,
              }}
              allowfullscreen
              allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture;"
            ></iframe>
          </div>
        </container>

        <container className={subSection}>
          <p align="center">
            <i>Processional</i>
          </p>
          <div
            style={{
              left: 0,
              width: "100%",
              height: "152px",
              position: "relative",
            }}
          >
            <iframe
              title="meames-processional"
              src="https://open.spotify.com/embed/track/1s0fMT0Rkp1l9ugVd6nLcL?utm_source=oembed"
              style={{
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
                border: 0,
              }}
              allowfullscreen
              allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture;"
            ></iframe>
          </div>
        </container>

        <container className={subSection}>
          <p align="center">
            <i>Post-ceremony</i>
          </p>
          <div
            style={{
              left: 0,
              width: "100%",
              height: "352px",
              position: "relative",
            }}
          >
            <iframe
              title="meames-post-ceremony"
              src="https://open.spotify.com/embed/playlist/5mOZx7cMvcsq3SK9RLGZQl?utm_source=oembed&theme=0"
              style={{
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
                border: 0,
              }}
              allowfullscreen
              allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture;"
            ></iframe>
          </div>
        </container>

        <container className={subSection}>
          <p align="center">
            <i>Recessional</i>
          </p>
          <div
            style={{
              left: 0,
              width: "100%",
              height: "152px",
              position: "relative",
            }}
          >
            <iframe
              title="meames-recessional"
              src="https://open.spotify.com/embed/track/4JRem7xHp2l0kmUvt9zCKu?utm_source=oembed"
              style={{
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
                border: 0,
              }}
              allowfullscreen
              allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture;"
            ></iframe>
          </div>
        </container>

        <container className={subSection}>
          <p align="center">
            <i>Drinks reception & wedding breakfast</i>
          </p>
          <div
            style={{
              left: 0,
              width: "100%",
              height: "352px",
              position: "relative",
            }}
          >
            <iframe
              title="meames-breakfast-playlist"
              src="https://open.spotify.com/embed/playlist/3FXC6IrFOHYGaGNxjJj9CR?utm_source=oembed&theme=0"
              style={{
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
                border: 0,
              }}
              allowfullscreen
              allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture;"
            ></iframe>
          </div>
        </container>

        <container className={subSection}>
          <p align="center">
            <i>Grand entrance</i>
          </p>
          <div
            style={{
              left: 0,
              width: "100%",
              height: "152px",
              position: "relative",
            }}
          >
            <iframe
              title="meames-grand-entrace"
              src="https://open.spotify.com/embed/track/7zvI4yWm0ZePh29D6WlKWM?utm_source=oembed"
              style={{
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
                border: 0,
              }}
              allowfullscreen
              allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture;"
            ></iframe>
          </div>
        </container>

        <container className={subSection}>
          <p align="center">
            <i>Evening reception</i>
          </p>
          <div
            style={{
              left: 0,
              width: "100%",
              height: "352px",
              position: "relative",
            }}
          >
            <iframe
              title="meames-drinks-break-playlist"
              src="https://open.spotify.com/embed/playlist/5JnSs93S6uoN5LjvafVi3X?utm_source=oembed&theme=0"
              style={{
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
                border: 0,
              }}
              allowfullscreen
              allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture;"
            ></iframe>
          </div>
        </container>

        <container className={subSection}>
          <p align="center">
            <i>First dance</i>
          </p>
          <div
            style={{
              left: 0,
              width: "100%",
              height: "152px",
              position: "relative",
            }}
          >
            <iframe
              title="meames-first-dance"
              src="https://open.spotify.com/embed/track/2bAt0NifIu9x0IXiufIYFb?utm_source=oembed"
              style={{
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
                border: 0,
              }}
              allowfullscreen
              allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture;"
            ></iframe>
          </div>
        </container>

        <container className={subSection}>
          <p align="center">
            <i>Dancefloor</i>
          </p>
          <div
            style={{
              left: 0,
              width: "100%",
              height: "352px",
              position: "relative",
            }}
          >
            <iframe
              title="meames-dancefloor-playlist"
              src="https://open.spotify.com/embed/playlist/0Ngty47TI5mBUBYAoe4Iwn?utm_source=oembed&theme=0"
              style={{
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
                border: 0,
              }}
              allowfullscreen
              allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture;"
            ></iframe>
          </div>
        </container>
      </container>
    </Layout>
  )
}

export default PlaylistPage
